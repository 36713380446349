import { MetaDefinition } from '@angular/platform-browser';

export const metaTags = {
  tags: [
    {
      name: 'description',
      content: 'Маркетплэйс видеобзоров и хороших риелторов',
    },
    {
      name: 'keywords',
      content:
        // eslint-disable-next-line max-len
        'витрина, витрина кз, витрина Астана, витрина Нур-Султан, витрина компания, витрина недвижимость, витрина недвижимости, витрина продажа, витрина приложение, витрина сайт, юрта, юрта кз, юрта сайт, юрта Астана, юрта Нур-Султан, юрта компания, юрта недвижимости, юрта недвижимость, юрта продажа, юрта приложение, jurta, jurta кз, jurta сайт, jurta Астана, jurta Нур-Султан, jurta компания, jurta недвижимости, jurta недвижимость, jurta продажа, jurta приложение, квартиры в астане, квартиры в нур-султане, купить недвижимость, продать недвижимость, снять недвижимость, сдать недвижимость, купить квартиру, продать квартиру, снять квартиру, сдать квартиру, купить дом, продать дом, снять дом, сдать дом, купить частный дом, продать частный дом, снять частный дом, сдать частный дом, купить коммерцию, продать коммерцию, снять коммерцию, сдать коммерцию, недвижимость, недвижимость казахстан, крыша, крыша кз',
    },
  ] as MetaDefinition[],
};
