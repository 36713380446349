import * as gm from 'generated/api/gm.api';

type GetShortAddressList = gm.paths['/open-api/address/getShortStreetsByParent'];
export type GetShortAddressListParams = GetShortAddressList['post']['parameters']['body']['filterDto'];
export type ShortAddressList = GetShortAddressList['post']['responses']['200']['schema'];

type GetStreetByCode = gm.paths['/open-api/address/getStreetByCode/{streetCode}']['get'];
export type GetStreetByCodeParams = GetStreetByCode['parameters']['path']['streetCode'];
export type GetStreetByCodeResp = GetStreetByCode['responses']['200']['schema'];

type GetShortStreetListByParent = gm.paths['/open-api/address/getShortStreetsByParent']['post'];
export type GetShortStreetListByParentParams = GetShortStreetListByParent['parameters']['body']['filterDto'];
export type GetShortStreetListByParentResp = GetShortStreetListByParent['responses']['200']['schema'];

type GetChildAddressObjectList = gm.paths['/open-api/address/getObjects']['get'];
export type GetChildAddressObjectListQueryParams = GetChildAddressObjectList['parameters']['query'];
export type GetChildAddressObjectListResp = GetChildAddressObjectList['responses']['200']['schema'];

type GetAddressObjectListByParentAddressObjectId = gm.paths['/open-api/address/getObjectListByParent/{parentId}']['get'];
export type GetAddressObjectListByParentAddressObjectIdParams = GetAddressObjectListByParentAddressObjectId['parameters']['path']['parentId'];
export type GetAddressObjectListByParentAddressObjectIdResp = GetAddressObjectListByParentAddressObjectId['responses']['200']['schema'];

export interface AddressObject {
  id: number;
  code: string;
  name: string;
  parentCodeCounty?: string;
}

export enum AddressType {
  Country = 'Country',
  AllCity = 'AllCity',
}

export enum AddressObjectTypeList {
  Country = 179,
  MainCity = 1,
  District = 2,
  AreaCenterCity = 49,
  City = 57,
  Region = 178,
}

export enum CountryList {
  '001' = 'kazakhstan',
  '003' = 'uzbekistan',
}
