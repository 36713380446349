import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { DeviceService } from '@helpers/device.service';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { SettingsService } from './settings.service';
import { Meta, Title } from '@angular/platform-browser';

import { metaTags } from './meta';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  #registerDevice$: Subscription;
  isMobile = false;
  private destroy$: Subject<void> = new Subject<void>();
  @HostListener('window:popstate', ['$event'])
  onPopState() {
    if (this._settingsService.modalRef) {
      this._settingsService.modalRef.close();
    }
  }

  constructor(
    private _deviceService: DeviceService,
    private _settingsService: SettingsService,
    private _titleService: Title,
    private _metaService: Meta,
  ) {}

  ngOnInit() {
    this.#registerDevice$ = this._deviceService.getDeviceUUID().pipe(takeUntil(this.destroy$)).subscribe();

    this._settingsService
      .isMobile()
      .pipe(takeUntil(this.destroy$))
      .subscribe((isMobile) => {
        this.isMobile = isMobile;
      });
    metaTags.tags.forEach((tag) => {
      this._metaService.updateTag({
        name: tag.name,
        content: tag.content,
      });
    });
    this._titleService.setTitle('Витрина Недвижимости');
  }

  ngOnDestroy() {
    if (this.#registerDevice$?.closed === false) {
      this.#registerDevice$.unsubscribe();
    }
    this.destroy$.next();
    this.destroy$.complete();
  }
}
