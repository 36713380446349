import { InjectionToken } from '@angular/core';

export const JWT_TOKEN_KEY = 'token';

export const CURRENT_USER_KEY = 'currentUser';

export const CLIENT_ID = 'htc';

export const VIEW_MANAGER_URI = new InjectionToken('viewManagerURI');

export const USER_MANAGER_URI = new InjectionToken('userManagerURI');

export const DATA_MANAGER_URI = new InjectionToken('dataManagerURI');

export const FILE_MANAGER_URI_TOKEN = new InjectionToken<string>('fileManagerURI');

export const UZBEKISTAN_COUNTRY_TOKEN = new InjectionToken<number>('uzbekistanCountry');

export const OBJECT_TYPE_ID_TOKEN = new InjectionToken<OBJECT_TYPE_ID>('objectTypeId');

export const SLOGAN = 'маркетплейс видеообзоров и хороших риелторов';

export enum OBJECT_TYPE_ID {
  Buy = 1,
  Sell = 2,
}

export const CITY_KEY = 'city';

export const NUMBER_AGENT_KEY = 'numberAgentSession';

export const IS_HIDE_CALL_CENTER_NUMBER = 'isHideCallCenterNumber';

export const MAX_WIDTH_MOBILE = 600;

export const KZ_COUNTRY_CODE = '001';
export const CYPRUS_COUNTRY_CODE = '002';
export const UZ_COUNTRY_CODE = '003';

export const CURRENCY_SYMBOL = {
  uzbekistan: 'у.е.',
  kazakhstan: '₸',
};

export const PREFIX_PHONE = {
  [KZ_COUNTRY_CODE]: '+7',
  [CYPRUS_COUNTRY_CODE]: '+7',
  [UZ_COUNTRY_CODE]: '+998',
};

export const MASK_PHONE = {
  [KZ_COUNTRY_CODE]: ' (000) 000-0000',
  [CYPRUS_COUNTRY_CODE]: ' (000) 000-0000',
  [UZ_COUNTRY_CODE]: ' (00)000-0000',
};

export const PHONE_LENGTH = {
  [KZ_COUNTRY_CODE]: 10,
  [UZ_COUNTRY_CODE]: 9,
  [CYPRUS_COUNTRY_CODE]: 10,
};
