import { Injectable } from '@angular/core';
import { PrincipalService } from '@app/principal/service/principal.service';
import { CURRENT_USER_KEY } from 'const';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class AuthGuard {
  constructor(private principal: PrincipalService) {}

  canActivate(): Observable<boolean> {
    return this.principal.currentUser().pipe(map((user) => !!user || !!localStorage.getItem(CURRENT_USER_KEY)));
  }
}
