import { Component } from '@angular/core';
import { SettingsService } from '@app/settings.service';
import { SellRealPropertyApplicationFormComponent } from '@modules/real-property-lead/sell-real-property-application-form.component';
import { DialogService } from 'primeng/dynamicdialog';
@Component({
  selector: 'app-mobile-header',
  templateUrl: './mobile-header.component.html',
  styleUrls: ['./mobile-header.component.scss'],
})
export class MobileHeaderComponent {
  socialLinks = [
    {
      icon: 'assets/images/instagram-color.svg',
      title: 'Instagram',
      alt: 'instagram',
      link: 'https://www.instagram.com/vitrina.uz',
    },
    {
      icon: 'assets/images/telegram-color.svg',
      title: 'Telegram',
      alt: 'telegram',
      link: 'https://t.me/ivitrinauz ',
    },
    {
      icon: 'assets/images/youtube-color.svg',
      title: 'YouTube',
      alt: 'youtube',
      link: 'https://youtube.com',
    },
  ];
  constructor(
    private dialogService: DialogService,
    private settingsService: SettingsService,
  ) {}

  public openRealPropertyLead(): void {
    this.dialogService.open(SellRealPropertyApplicationFormComponent, {
      showHeader: false,
      styleClass: 'mobile-sell-real',
      contentStyle: {
        overflow: 'auto',
        'border-radius': '20px',
      },
    });
  }

  isShowContact() {
    return this.settingsService.isHideCallCenterNumber;
  }
}
